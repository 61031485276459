<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="hidden">
    <vs-sidebar hover-expand reduce open v-model="name">
      <template #logo>
        <!-- ...img logo -->
        <router-link to="/">
          <img src="@/assets/images/SPRINT_GAMING_PNG-.png" />
        </router-link>
      </template>
      <div v-for="(item, index) in getMenu(savedRole)" :key="index">
        <template v-if="item.required">
          <vs-sidebar-item v-if="!item.isTitle && !item.subItems && !item.required.beAdmin" :id="item.link">
            <template #icon v-if="item.icon">
              <router-link :to="item.link">
                <i :class="item.icon"></i>
              </router-link>
            </template>
            <router-link :to="item.link">
              {{ item.label }}
            </router-link>
          </vs-sidebar-item>

          <!-- GRUPOS -->

          <vs-sidebar-group v-if="item.subItems && !item.required.notShow">
            <!-- Cabecera -->
            <template #header>
              <vs-sidebar-item arrow v-if="!item.required.beAdmin">
                <template #icon v-if="item.icon">
                  <i :class="item.icon"></i>
                </template>
                {{ item.label }}
              </vs-sidebar-item>
            </template>
            <!-- Fin Cabecera -->
            <vs-sidebar-item v-for="(subI, index) in item.subItems" :key="index" :id="subI.link">
              <template #icon v-if="subI.icon">
                <template v-if="subI.required">
                  <template v-if="!subI.required.beAdmin">
                    <router-link :to="subI.link">
                      <i :class="subI.icon" :style="`display: ${!subI.required.beAdmin ? 'block' : 'none'
                        }`"></i>
                    </router-link>
                  </template>
                </template>
              </template>
              <template v-if="subI.required">
                <template v-if="!subI.required.beAdmin">
                  <router-link :to="subI.link" :style="`display: ${!subI.required.beAdmin ? 'block' : 'none'
                    }`">
                    {{ subI.label }}
                  </router-link>
                </template>
              </template>
            </vs-sidebar-item>
          </vs-sidebar-group>
        </template>
        <template v-else>
          <vs-sidebar-item v-if="!item.isTitle && !item.subItems" :id="item.link">
            <template #icon v-if="item.icon">
              <router-link :to="item.link">
                <i :class="item.icon"></i>
              </router-link>
            </template>
            <router-link :to="item.link">
              {{ item.label }}
            </router-link>
          </vs-sidebar-item>

          <!-- GRUPOS -->

          <vs-sidebar-group v-if="item.subItems">
            <!-- Cabecera -->
            <template #header>
              <vs-sidebar-item arrow>
                <template #icon v-if="item.icon">
                  <i :class="item.icon"></i>
                </template>
                {{ item.label }}
              </vs-sidebar-item>
            </template>
            <!-- Fin Cabecera -->
            <vs-sidebar-item v-for="(subI, index) in item.subItems" :key="index" :id="subI.link"
              :style="`display: ${subI.required.beAdmin ? 'flex' : 'none'}`">
              <template #icon v-if="subI.icon && subI.required.beAdmin">
                <router-link :to="subI.link">
                  <i :class="subI.icon" :style="`display: ${subI.required.beAdmin ? 'block' : 'none'
                    }`"></i>
                </router-link>
              </template>
              <router-link :to="subI.link" :style="`display: ${subI.required.beAdmin ? 'block' : 'none'}`">
                {{ subI.label }}
              </router-link>
            </vs-sidebar-item>
          </vs-sidebar-group>
        </template>
      </div>
    </vs-sidebar>
  </div>
  <!-- Left Sidebar End -->
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      active: "Inicio",
      name: this.routeName,
      savedRole: ''
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              break;
            case "light":
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "icon":
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-sidebar-size");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    routeName(value) {
      this.name = value;
    },
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    },
    _activateMenuDropdown() {
      this._removeAllClass("mm-active");
      this._removeAllClass("mm-show");

      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      const paths = [];

      for (var i = 0; i < links.length; i++) {
        paths.push(links[i]["pathname"]);
      }
      var itemIndex = paths.indexOf(window.location.pathname);
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf("/");
        const item = window.location.pathname.substr(0, strIndex).toString();
        matchingMenuItem = links[paths.indexOf(item)];
      } else {
        matchingMenuItem = links[itemIndex];
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) childAnchor.classList.add("mm-active");
              if (childDropdown) childDropdown.classList.add("mm-active");

              const parent4 = parent3.parentElement;
              if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                  parent5.classList.add("mm-active");
                  const childanchor = parent5.querySelector(".is-parent");
                  if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                  }
                }
              }
            }
          }
        }
      }
    },
    onRoutechange() {
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition =
            document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
        }
      }, 300);
    },
    getUserData() {
      let user = JSON.parse(localStorage.getItem("user"))
      const role = user.user.role.name
      this.savedRole = role
    },
  },
  computed: {
    user() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user;
    },
    role() {
      return this.$store.getters["user/getUserRole"];
    },
    ...mapGetters({
      getMenu: 'menu/getMenu'
    })
  },
  mounted() {
    this.getUserData();
  }
};
</script>

<style scoped>
.logo-sm {
  height: auto;
  width: 100px;
  margin: 0 auto;
  position: relative;
  left: 0;
}

.logo-img {
  height: 70px;
  width: auto;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.min-logo {
  height: auto;
  width: 250%;
  object-fit: cover;
}
</style>

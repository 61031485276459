<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="20" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="20" />
            </span>
          </router-link>
        </div>

        <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">

        <!-- SELECT LANGUAGE -->

        <b-dropdown toggle-class="header-item" variant="white" menu-class="position-absolute top-100">

          <template v-slot:button-content class="">
            <div class="">
              <p class="m-0">{{ $i18n.locale.toUpperCase() }}</p>
              <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
            </div>

          </template>

          <span style="cursor: pointer;" class="dropdown-item" @click="changeLang" data-value="es">ES</span>
          <span style="cursor: pointer;" class="dropdown-item" @click="changeLang" data-value="en">EN</span>
          <!-- <span style="cursor: pointer;" class="dropdown-item">PT</span> -->

        </b-dropdown>



        <b-dropdown class="d-inline-block" toggle-class="header-item" right variant="white"
          menu-class="position-absolute top-100">
          <template v-slot:button-content>
            <div class="d-flex align-items-end">
              <vs-avatar size="40" badge badge-color="success">
                <img class="rounded-circle header-profile-user" src="@/assets/images/users/pngegg.png"
                  alt="Header Avatar" />
              </vs-avatar>
              <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{{ userName }}</span>
              <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
            </div>
          </template>

          <!-- item-->
          <router-link class="dropdown-item" :to="`/users/${id}`">
            <i class="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.profile")
            }}</span>
          </router-link>
          <!-- <a class="dropdown-item" href="#">
            <i class="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.mywallet")
            }}</span>
          </a> -->
          <router-link class="dropdown-item d-block" to="/settings">
            <i class="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.settings")
            }}</span>
          </router-link>

          <a class="dropdown-item btn" @click.prevent="logoutUser">
            <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.logout")
            }}</span>
          </a>
        </b-dropdown>

      </div>
    </div>
  </header>
</template>

<script>
// import { saveActions } from "../helpers/userActions/userActions";

// import Swal from 'sweetalert2';

export default {
  data() {
    return {
      id: JSON.parse(localStorage.getItem("user")).user._id,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish",
        },
        {
          flag: require("@/assets/images/flags/china.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
      userName: null,
    };
  },
  mounted() {

    this.value = this.languages.find((x) => x.language === this.$i18n.locale.toLowerCase());
    this.text = this.value.title;
    this.flag = this.value.flag;
    let user = JSON.parse(localStorage.getItem("user"));
    this.userName = user.user.userName;


  },
  methods: {
    async changeLang(e) {

      // const { isConfirmed } = await Swal.fire({
      //   title: this.$t('helpers.changeLang'),
      //   confirmButtonText: this.$t('helpers.continue'),
      //   icon: "info"
      // })

      const lang = e.target.getAttribute("data-value");
      this.$store.commit("lang/setLang", lang)
      this.$i18n.locale = lang;

      // if (isConfirmed) {
      //   this.$router.go(0);
      // }

    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    logoutUser() {
      this.$store.commit("user/logout");
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>


<style scoped>
.btn-group>.btn {
  display: flex !important;
  align-items: flex-end !important;
  padding: 15px;
}
</style>
<template>
  <div class="footer">
    <img src="@/assets/images/SPRINT_GAMING_PNG.png" class="space-img" />
    <div class="text-light">&copy; copyright</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer {
  text-align: center;
  background-color: rgb(40, 42, 40);
  border-top: 1px solid rgba(255, 255, 255, 0.274);
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  left: 0;
}

.space-img {
  margin-right: 10px;
  height: 40px;
  width: auto;
  object-fit: fill;
}
</style>
